import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import BannerImage from '../../../sub-components/banner-image';

export default function Construction() {

  const baseUrl = process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL_PROD;



  const [openTitle, setOpenTitle] = useState(null);

  const toggleDataManagement = (title) => {
    if (openTitle === title) {
      setOpenTitle(null);
    } else {
      setOpenTitle(title);
    }
  };


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const solutionid = searchParams.get('id');
  console.log('Selected Manager Name:', solutionid);

  const [solutionData, setSolutionData] = useState(null);
  console.log(solutionData)
  useEffect(() => {
    if (solutionid) {
      fetch(`${baseUrl}/allDataInfo/solutionInfo.php?id=${solutionid}`, {

      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('API response data:', data);

          if (Array.isArray(data) && data.length > 0) {
            setSolutionData(data[0]);
          } else {
            console.error('No manager data found.');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [solutionid]);

  if (solutionData === null) {
    return <>Loading...</>;
  }


  return (
    <>
      <div className=' bg-[#ffff] pb-10'>
        <BannerImage />

        <section className='relative mx-5 mt-5 xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)] overflow-hidden'>
          <h4 className='text-[14px] font-thin text-[#333333]'><Link to='/'>Home / Solutions</Link> / {solutionData.title}</h4>
          <h1 className='text-[24px] lg:text-[28px] font-semibold text-[#333333] mt-5'>{solutionData.title}</h1>

          <div className=' gap-5 mt-5'>
            {/* <img src={`/assets/${solutionData.image}`} alt='' className='w-full h-auto md:h-[350px]' /> */}
            <div className=''>
              {/* <p dangerouslySetInnerHTML={{ __html: solutionData.description }}></p> */}
              <h1 className='text-justify text-[16px] md:text-[24px] lg:text-[32px] font-medium text-[#333333]'>{solutionData.subTitle}</h1>
            </div>
          </div>

          {/* <div className='text-justify md:text-center text-[16px] md:text-[24px] font-bold text-[#666666] mt-5 md:mt-10'>
                
            </div> */}

          {solutionData.solutionInfo && solutionData.solutionInfo.length > 0 &&
            <div className="block mt-5 w-full">
              {solutionData.solutionInfo.map((sol) => (
                <div className="block mt-5" key={sol.solution_title}>
                  <div className="relative w-full">
                    <input
                      type="checkbox"
                      className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                      id="dataManagementContent"
                      onChange={() => toggleDataManagement(sol.solution_title)}
                    />
                    <div className="h-12 w-full pl-5 flex items-center border-l-4 border-[#072FA5]">
                      <h1 className="!text-[#333333] text-[16px] font-semi-bold">
                        {sol.solution_title}
                      </h1>
                    </div>
                    <div
                      className={`overflow-hidden transition-all duration-500 pl-5 grid grid-cols-1 md:grid-cols-2 gap-5 
              ${
                        // openTitle === sol.solution_title ? 'h-auto' : 'h-0' 
                        'h-auto'
                        }
                `}
                      id="dataManagementContent"
                    >
                      <div className="text-[16px] md:text-[16px] font-normal text-[#333333]">
                        <p>{sol.solution_description}</p>
                        {sol.solution_features.length > 1 &&
                          <div>
                            <h1 className="text-[20px] text-[#072FA5] !font-semibold my-5">

                              Features

                            </h1>
                            {sol.solution_features.map((features) => (
                              <ul className="list-disc md:leading-8 ml-5" key={features}>
                                <li>{features}</li>
                              </ul>
                            ))}
                          </div>
                        }

                      </div>
                      <div className="w-full h-auto md:h-[390px] object-cover">
                        <img src={`/assets/${sol.solution_image}`} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}


            </div>
          }
        </section>
      </div>
    </>
  )
}
